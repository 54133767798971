body {
    font-size: 1rem !important;
}
.badge {
    padding: 0.555em 1em !important;
}
.card {
    box-shadow: 5px 5px 10px gray;
}
// .card .card-header {
//     border-bottom: solid 1px #009376;
// }
.c-sidebar-nav .c-sidebar-nav-item .c-sidebar-nav-link:hover {
    border-bottom: solid 1px #10238a;
}
.c-sidebar-nav .c-sidebar-nav-item .c-sidebar-nav-link.c-active {
    border-right: solid 5px #10238a;
    border-bottom: solid 1px #10238a;
}
.modal-dialog .modal-content .modal-header {
    border-bottom: solid 1px #009376;
}
.modal-dialog .modal-content .modal-footer {
    border-top: solid 1px #009376;
}
.basic-single {
    width: 50%;
}
.basic-single-full {
    width: 100%;
}